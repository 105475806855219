import React from "react";

// Customizable Area Start
import { Box, Container, InputAdornment, Typography, Button, TextField, Modal, Snackbar, Alert, IconButton,  } from '@mui/material';
import { VisibilityOutlined, VisibilityOffOutlined } from '@mui/icons-material';
import { styled, createTheme, ThemeProvider, } from '@mui/material/styles';
import { phoneCountrySvg, AqsatLogo, CrossSvg } from "./assets";
import { Formik, Field, ErrorMessage, Form } from 'formik';
import * as Yup from 'yup';
import OtpInput from 'react-otp-input';
import { getDirectionsByLanguage, getPoppinsFontByLanguage, getTextAlignByLanguage, getTextAlignRightByLanguage, translate } from "../../../../packages/components/src/Translate";

const getValidationSchema = () => {
    return Yup.object({
        mobileNumber: Yup.string()
            .matches(/^\d+$/, translate('mobileNumberInvalidLogin'))
            .required(translate('mobileNumberRequiredLogin'))
            .matches(/^05/, translate('mobileNumberStartsWith05Login'))
            .matches(/^\d{10}$/, translate('mobileNumberLengthLogin'))
            .trim(),
        userPass: Yup.string()
            .required(translate('passwordRequiredLogin'))
            .trim(),
    });
};
const initialValues = {
    mobileNumber: '',
    userPass: ''
};



const theme = createTheme(
    {
        palette: {
            secondary: {
                main: "#376fd0",
                contrastText: "#fff",
            },
            primary: {
                main: "#fff",
                contrastText: "#fff",
            },
            error: {
                main: "#f44336",
                contrastText: "#fff",
            }
        },
        typography: {
            fontFamily:getPoppinsFontByLanguage() ,
            h1: {
                fontWeight: 700,
                fontSize: "28px",
            },
            h6: {
                fontWeight: 500,
            },

            subtitle1: {
                margin: "20px 0px",
            },
        },
        components: {
            MuiInput: {
                styleOverrides: {
                    root: {
                        borderBottom: "1px solid #CBD5E1",
                        "&.Mui-focused": {
                            borderBottom: "1px solid #CBD5E1",
                        },
                    }
                }
            }
        },
    });
// Customizable Area End

import EmailAccountLoginController, {
    Props,
} from "./EmailAccountLoginController";

export default class PhoneNumberLogin extends EmailAccountLoginController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }
    // Customizable Area Start
    renderForm = () => {
        return (<Box>

            <Formik
                validationSchema={getValidationSchema}
                data-test-id="address-dialog"
                initialValues={initialValues}
                onSubmit={(values, actions) => {
                    this.handleSubmit(values, actions);
                }
                }

            >
                {({
                    values,
                    touched,
                    errors,
                    handleBlur,
                    handleSubmit,
                    handleChange,
                    setFieldValue,
                    isValid,
                }) => {
                    return (
                        <Form className="styledForm" onSubmit={handleSubmit}>
                            <Field
                            data-test-id="phoneNumberTestID"
                                as={TextField}
                                variant="standard"
                                className="PhoneInput"
                                placeholder={translate("mobileNumberPlaceholderLogin")}
                                name="mobileNumber"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img src={phoneCountrySvg} alt="country code" className="phoneIconStyle" 
                                             style={{ 
                                                paddingLeft: '4px' ,
                                            }}/>
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    const value = event.target.value.replace(/\D/g, ''); 
                                    setFieldValue("mobileNumber", value);
                                }} 
                                onBlur={handleBlur}
                                value={values.mobileNumber}
                                error={touched.mobileNumber && Boolean(errors.mobileNumber)}
                                helperText={touched.mobileNumber && errors.mobileNumber}
                            />
                            <Field
                                as={TextField}
                                name="userPass"
                                data-test-id="userPassword"
                                type={this.state.showPassword ? 'text' : 'password'}
                                className="PhoneInput"
                                variant='standard'
                                placeholder={translate("passwordPlaceholderLogin")}
                                onChange={(e:any) => this.handleUserPassChange(e, setFieldValue)}
                                onBlur={(e:any) => this.handleUserPassBlur(e, setFieldValue, handleBlur)}
                                value={values.userPass}
                                error={touched.userPass && Boolean(errors.userPass)}
                                helperText={<><ErrorMessage name="userPass" /> {(this.state.sendOtpErrorMsg) ? translate("wrongPasswordMsgLogin") : ''}</>}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end"  >
                                            <IconButton onClick={this.togglePasswordVisibility} edge="end" >
                                                {this.state.showPassword ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                            <Typography data-test-id="forgotPasswordBtn" className="forgotPass" onClick={() => this.handleNavigation('ForgotPassword')} >
                                {translate("forgotPasswordLogin")}
                            </Typography>
                            <Button data-test-id="submitBtn" type="submit" className="LoginBtn" >
                                {translate("loginBtnLogin")}
                            </Button>
                        </Form>
                    );
                }}
            </Formik>

        </Box>)
    }
    // Customizable Area End
    render() {
        return (
            // Customizable Area Start
            // Required for all blocks
            <div id="login_main_block">
                <ThemeProvider theme={theme}>
                    <StyledWrapper>
                        <Box component={'div'} className="imageWrapper" >
                            <img src={AqsatLogo} alt="Aqsat_logo" onClick={() => this.handleNavigation('Home')} style={{cursor: 'pointer'}} data-test-id="homeLogoBtn"/>
                        </Box>
                        <Container maxWidth="xl" className="mainDivStyle" >
                            <Typography
                                variant="h4"
                                gutterBottom
                                sx={Styles.HeadingStyle}
                            >
                                {translate("loginTitle")}
                            </Typography>
                            {this.state.sendOtpErrorMsg && <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center', }} className="snackbarStyle" open={this.state.snackbarErrorMsg} autoHideDuration={4000} onClose={() => this.handleSnackbarself()} data-test-id="snackBar" >
                                <Alert severity="error"> {this.state.sendOtpErrorMsg} </Alert>
                            </Snackbar>}
                            {this.renderForm()}
                            <Box className="RegistrationBox">
                                <Typography className="registerBoxFirst">
                                    {translate("dontHaveAccountLogin")}
                                </Typography>
                                <Typography data-test-id="signUpBtn" onClick={() => this.handleNavigation('Signup')} className="registerBoxSecond">
                                    {translate("registerNowLogin")}
                                </Typography>
                            </Box>

                            <Modal

                                open={this.state.isVisible}
                                onClose={this.hideModal}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box sx={modalStyle}>
                                    <Box sx={Styles.Wrapper}>
                                        <Box sx={Styles.crossWrap}>
                                            <Box data-test-id="closeModal" onClick={() => this.hideModal()} component="img" src={CrossSvg} sx={Styles.crossImg} alt="Cross Button" />
                                        </Box>
                                        <Typography variant="h4" gutterBottom sx={Styles.HeadingStyleOtp}>
                                            {translate("verifyYourNumberLogin")}
                                        </Typography>
                                        <Typography sx={Styles.mobileCode}>
                                            {translate("otpSentMessageLogin")}
                                        </Typography>
                                        <Box sx={Styles.OtpInputWrapper}>
                                            <OtpInput
                                                value={this.state.otp}
                                                onChange={this.handleOtpChange}
                                                numInputs={4}
                                                renderInput={(props) => <input {...props} style={{
                                                    border: this.state.otpError ? '2px solid #E00307' : '2px solid #D7D7D7',
                                                    borderRadius: '16px',
                                                    width: '50px',
                                                    height: '50px',
                                                    textAlign: 'center',
                                                }} />}
                                            />
                                        </Box>
                                        {this.state.otpError && (
                                            <Typography color="error" sx={Styles.otpError}>
                                                {this.state.otpError}
                                            </Typography>
                                        )}
                                        {this.state.timer > 0 ? (
                                            <Typography sx={Styles.OtpTimer}>{`0:${this.state.timer}`}</Typography>
                                        ) : (
                                            <Typography sx={Styles.ResendCode}>
                                                {!this.state.otpError && translate("didntGetCodeLogin")}
                                                <Typography data-test-id="resend-otp-button" onClick={this.resendOtp} sx={Styles.ClickResendCode}>
                                                    {translate("clickToResendLogin")}
                                                </Typography>
                                            </Typography>
                                        )}
                                        <Button disabled={this.state.otp.length !== 4} sx={{
                                            ...Styles.VerifyButton,
                                            backgroundColor: this.state.otp.length === 4 ? 'blue' : 'gray',
                                            color: this.state.otp.length === 4 ? 'white' : 'darkgray',
                                            '&.Mui-disabled': {
                                                backgroundColor: 'lightgray',
                                                color: 'darkgray'
                                            }
                                        }} onClick={this.validateOtp}>
                                            {translate("verifyLogin")}
                                        </Button>
                                    </Box>
                                </Box>

                            </Modal>
                        </Container>
                    </StyledWrapper>
                </ThemeProvider>
            </div>

            // Customizable Area End
        );
    }

}

// Customizable Area End
const modalStyle = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-54%, -50%)",
    bgcolor: "background.paper",
    borderRadius: '16px',
    marginLeft: '16px',
    marginRight: '16px',

};

const StyledWrapper = styled(Box)({
    "& .snackbarStyle": {
        position: "absolute",
        "@media (max-width:600px)": {
            marginTop: '160px',
            width: '100%',
        },
        "@media (min-width:600px)": {
            marginTop: '150px',
            width: '100%'
        },
        "@media (min-width:1280px)": {
            marginTop: '240px'
        },
    },

    "& .modalStyle": {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-54%, -50%)",
        bgcolor: "background.paper",
        borderRadius: '16px',
        marginLeft: '16px',
        marginRight: '16px',
    },
    "& .styledForm": {
        width: '100%',
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: "0px",
        direction:getDirectionsByLanguage(),
        position:'relative',
        "& .MuiFormHelperText-root": {
            fontSize: '12px',
            fontWeight: 400,
            color: 'rgb(211, 47, 47)',
            textAlign: getTextAlignRightByLanguage()
        },
    },
    "& .imageWrapper": {
        marginBottom: '25px',
        marginTop: '30px',
        display: 'flex',
        "@media (max-width: 600px)": {
            marginTop: '20px',
            paddingLeft: '16px',
        },
        "@media (min-width: 600px)": {
            marginTop: '25px',
            justifyContent: 'center',
        },
        "@media (min-width: 1280px)": {
            marginTop: '30px',
            justifyContent: 'flex-start',
            paddingLeft: '99px',
        },
        "@media (min-width: 1668px)": {
            marginTop: '30px',
            justifyContent: 'flex-start',
            paddingLeft: '226px',
        },
    },
    "& .mainDivStyle": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        marginBottom: "15%",
        "@media (max-width: 600px)": {
            marginTop: '63px',
        },
        "@media (min-width: 600px)": {
            marginTop: '58px',
        },
        "@media (min-width: 1280px)": {
            marginTop: '150px',
        },
    },
    "& .centerContent": {
        textAlign: "center",
    },
    "&. HeadingStyle": {
        fontSize: "28px",
        marginBottom: "67px",
        fontWeight: 700,
        fontFamily:getPoppinsFontByLanguage(),
        lineHeight: "29.33px",
        textAlign: "center",
        "@media (max-width: 600px)": {
            marginTop: '88px',
        },
    },
    "& .forgotPass": {
        fontFamily: getPoppinsFontByLanguage(),
        marginTop: '5px',
        marginBottom: '36px',
        maxWidth: "520px",
        display: 'flex',
        width: '100%',
        cursor: 'pointer',
        [theme.breakpoints.down("sm")]: {
            height: "44px",
            marginBottom: "36px",
        },
        "@media (max-width: 1280px)": {
            marginTop: '4px',
        },
    },
    "& .PhoneInput": {
        fontFamily: getPoppinsFontByLanguage(),
        maxWidth: "520px",
        display: 'flex',
        width: '100%',
        height: "44px",
        "@media (max-width:600px)": {
            height: "44px",
            marginBottom: "32px",
        },
        "@media (min-width:600px)": {
            height: "44px",
            marginBottom: "32px",
        },
        "@media (min-width:1280px)": {
            height: "44px",
            marginBottom: "31px",
        },
        "@media (min-width:1668px)": {
            height: "44px",
            marginBottom: "31px",
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottomColor: '#6200EA',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#6200EA',
            '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                borderBottomColor: '#6200EA',
            },
        },
    },
    "& .phoneIconStyle": {
        marginRight: "8px",
    },
    "& .LoginBtn": {
         fontFamily: getPoppinsFontByLanguage(),
        textAlign:getTextAlignByLanguage(),
        width: "520px",
        minHeight: "44px",
        fontSize: "20px",                      
         borderRadius: "6px",
        backgroundColor: "#1D36BB",
        color: "#fff",
        fontWeight: 700,
        textTransform: "none",
        "@media (max-width: 600px)": {
            width: "100%",
            height: "44px",
            padding: "18px 51px",
        },
        "&:hover": {
            backgroundColor: "#1D36BB",
            color: "#fff",
            boxShadow: "none",
        },
    },
    "& .otpInput": {
        border: "2px solid #1D36BB",
        borderRadius: "16px",
        width: "70px",
        height: "70px",
        marginBottom: "31px",
        marginRight: "10px",
        backgroundColor: "transparent",
        fontFamily:getPoppinsFontByLanguage(),
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "0px 0px 0px 22px",
        fontSize: "35px",
        fontWeight: "700",
        lineHeight: "29.33px",
        textAlign: "center",
        "@media (max-width: 600px)": {
            width: "70px",
            height: "70px",
            marginRight: "10px",
            marginBottom: "0px",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
    },
    "& .verifyButton": {
        width: "520px",
        height: "53px",
        padding: "19.5px 236px",
        borderRadius: "6px",
        backgroundColor: "#1D36BB",
        color: "#fff",
        fontWeight: 700,
        fontSize: "20px",
        marginBottom: "73px",
        textTransform: "none",
        "@media (max-width: 600px)": {
            width: "329px",
            height: "53px",
            padding: "19.5px 133.5px",
            marginBottom: "15px",
        },
        "&:hover": {
            backgroundColor: "#1D36BB",
            color: "#fff",
            boxShadow: "none",
        },
    },
    "& .WrapperStyled": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: 'column',
        position: 'relative',
        padding: '30px',
        "@media (max-width: 600px)": {
            padding: '16px',
            margin: '16px'
        },
    },
    "& .headingStyleOtp": {
        fontWeight: 700,
        fontSize: "28px",
        marginTop: '55px',
        marginBottom: "22px",
        fontFamily:getPoppinsFontByLanguage(),
        lineHeight: "29.33px",
        textAlign: "center",
    },
    "& .crossWrap": {
        display: "flex",
        alignItems: "end",
        justifyContent: 'flex-end',
        width: "100%",
        position: "relative",
    },
    "& .crossImg": {
        cursor: 'pointer'
    },
    "& .mobileCode": {
        marginBottom: "37px",
        "@media (max-width: 600px)": {
            width: '100%'
        },
    },
    "& .OtpInputWrapper": {
        display: "flex",
        alignItems: "row",
        justifyContent: "center",
        "@media (max-width: 600px)": {
            marginTop: '44px',
        },
    },
    "& .ResendCode": {
        marginBottom: "37px",
        display: "flex",
        "@media (max-width: 600px)": {
            marginBottom: "69px",
            display: "flex",
        },
    },
    "& .ClickResendCode": {
        marginLeft: "5px",
        fontFamily:getPoppinsFontByLanguage(),
        fontSize: "16px",
        fontWeight: "700",
        lineHeight: "29.33px",
        textAlign: "center",
        borderBottom: "1px solid black",
    },
    "& .otpError": {
        color: 'rgb(211, 47, 47)',
        marginBottom: '10px',
        fontSize: '14px',
    },
    "& .RegistrationBox": {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '52px',
        gap: '15px',
        "@media (max-width:1280px)": {
            gap: '5px',
            marginTop: '65px',
        },
        "& .registerBoxFirst": {
            fontFamily:getPoppinsFontByLanguage(),
            fontWeight: 400,
            fontSize: '20px',
            lineHeight: '29.33px',
            "@media (max-width:1280px)": {
                fontSize: '16px',
            },
        },
        "& .registerBoxSecond": {
            fontFamily:getPoppinsFontByLanguage(),
            cursor: 'pointer',
            fontWeight: 700,
            fontSize: '20px',
            lineHeight: '29.33px',
            "@media (max-width:1280px)": {
                fontSize: '16px',
            },
        },
    }
});

const Styles = {
    mainDivStyle: {
        display: "flex",
        marginTop: "125px",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        [theme.breakpoints.down("sm")]: {
            marginTop: "117px",
        },
        "@media (max-width: 768px)": {
            marginTop: '38px',
        },
    },
    centerContent: {
        textAlign: "center",
    },
    HeadingStyle: {
        fontFamily: getPoppinsFontByLanguage(),
        textAlign:getTextAlignByLanguage(),
        fontWeight: 700,
        fontSize: "28px",
        marginBottom: '60px',
        "@media (max-width: 600px)": {
            marginBottom: "60px",
            lineHeight: "42px",
        },
        "@media (min-width: 600px)": {
            marginBottom: "60px",
            lineHeight: "42px",
        },
        "@media (min-width: 1280px)": {
            marginBottom: "67px",
            lineHeight: "29.33px",
        },
        "@media (min-width: 1668px)": {
            marginBottom: "67px",
            lineHeight: "29.33px",
        },
    },
    forgotPass: {

        fontFamily: getPoppinsFontByLanguage(),
        textAlign:getTextAlignByLanguage(),
        marginTop: '5px',
        marginBottom: '36px',
        maxWidth: "520px",
        display: 'flex',
        width: '100%',
        cursor: 'pointer',
        [theme.breakpoints.down("sm")]: {
            height: "44px",
            marginBottom: "32px",
        },
    },
    phoneIconStyle: {
        marginRight: "8px",
    },
    LoginBtn: {

        fontFamily: getPoppinsFontByLanguage(),
        textAlign:getTextAlignByLanguage(),
        maxWidth: "520px",
        height: "44px",
        fontSize: "20px",
        padding: "19.5px 236px",
        borderRadius: "6px",
        backgroundColor: "#1D36BB",
        color: "#fff",
        fontWeight: 700,
        textTransform: "none",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            height: "44px",
            padding: "18px 51px",
        },
        "&:hover": {
            backgroundColor: "#1D36BB",
            color: "#fff",
            boxShadow: "none",
        },
    },

    mainModalStyle: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
    },
    otpInput: {
        border: "2px solid #1D36BB",
        borderRadius: "16px",
        width: "70px",
        height: "70px",
        marginBottom: "31px",
        marginRight: "10px",
        backgroundColor: "transparent",
        fontFamily:getPoppinsFontByLanguage(),
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "0px 0px 0px 22px",
        fontSize: "35px",
        fontWeight: "700",
        lineHeight: "29.33px",
        textAlign: "center",
        [theme.breakpoints.down("sm")]: {
            width: "70px",
            height: "70px",
            marginRight: "10px",
            marginBottom: "0px",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
    },
    VerifyButton: {
        width: "520px",
        height: "53px",
        padding: "19.5px 236px",
        borderRadius: "6px",
        backgroundColor: "#1D36BB",
        color: "#fff",
        fontWeight: 700,
        fontSize: "20px",
        marginBottom: "73px",
        textTransform: "none",
        [theme.breakpoints.down("sm")]: {
            width: "329px",
            height: "53px",
            padding: "19.5px 133.5px",
            marginBottom: "15px",
        },
        "&:hover": {
            backgroundColor: "#1D36BB",
            color: "#fff",
            boxShadow: "none",
        },
    },

    Wrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: 'column',
        position: 'relative',
        padding: '30px',
        flexWrap: 'wrap',
        [theme.breakpoints.down("sm")]: {
            padding: '16px',
            margin: '16px'
        },
    },
    HeadingStyleOtp: {
        fontFamily: getPoppinsFontByLanguage(),
        textAlign:getTextAlignByLanguage(),
        fontWeight: 700,
        fontSize: "28px",
        marginTop: '55px',
        marginBottom: "22px",
        lineHeight: "29.33px",
    },
    crossWrap: {
        display: "flex",
        alignItems: "end",
        justifyContent: 'flex-end',
        width: "100%",
        position: "relative",
    },
    crossImg: {
        cursor: 'pointer'
    },
    mobileCode: {
        marginBottom: "37px",
        fontFamily: getPoppinsFontByLanguage(),
        textAlign:getTextAlignByLanguage(),
        [theme.breakpoints.down("sm")]: {
            width: '100%'
        },
    },
    OtpInputWrapper: {
        display: "flex",
        justifyContent: 'center',
        marginBottom: "44px",
        alignItems: 'center',
        width: '100%',
        [theme.breakpoints.down("sm")]: {
            marginBottom: "44px",
        },
        "div": {
            gap: '10px',
            justifyContent: 'center',
        }
    },
    OtpBoxes: {
        display: 'flex',
        justifyContent: 'center',
        width: '100px',
        backgroundColor: 'red !important',
        marginRight: '10px !important'
    },
    ResendCode: {
        marginBottom: "37px",
        display: "flex",
        [theme.breakpoints.down("sm")]: {
            marginBottom: "69px",
            display: "flex",
        },
    },
    OtpTimer: {
        color: '#2C2C2E',
        marginBottom: '37px',
        lineHeight: '29.33px',
        size: '16px',
        fontWeight: 700
    },
    ClickResendCode: {
        marginLeft: "5px",
        fontFamily:getPoppinsFontByLanguage(),
        fontSize: "16px",
        fontWeight: "700",
        lineHeight: "29.33px",
        textAlign: "center",
        borderBottom: "1px solid black",

        [theme.breakpoints.down("sm")]: {
        },
    },
    otpError: {
        color: 'rgb(211, 47, 47)',
        marginBottom: '10px',
        fontSize: '14px',
    },


}




// Customizable Area End
