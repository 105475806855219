// Customizable Area Start
import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Container,
  Typography,
} from "@mui/material";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { getPoppinsFontByLanguage } from "../../../../packages/components/src/Translate";
import { Helmet } from 'react-helmet';
// Customizable Area End

import ContentManagementController, {
  Props,
  configJSON,
} from "./ContentManagementController";
// Customizable Area Start

const theme = createTheme(
  {palette: {
      primary: {
        main: "#fff",
        contrastText: "#fff",
      },
      secondary: {
        main: "#376fd0",
        contrastText: "#fff",
      },
      error: {
        main: "#f44336",
        contrastText: "#fff",
      }
    },
  typography: {
      fontFamily: getPoppinsFontByLanguage(),
    h6: {
      fontWeight: 500,
    },

    subtitle1: {
      margin: "20px 0px",
    },
  },
  });
// Customizable Area End


export default class ContentManagement extends ContentManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start


  // Customizable Area End

  render() {
  // Customizable Area Start 
    const {selectedLanguage , metaData} = this.state
    
  // Customizable Area End

    return (
      // Customizable Area Start
      <div id="main_block">
        <NavigationMenu navigation={this.props.navigation} id={""} isFooter={true} >
          <ThemeProvider theme={theme}>
          <Helmet>
              <meta charSet="utf-8" />
              <title>{metaData.data.attributes.meta_title}</title>
              <meta name="description" content={metaData.data.attributes.meta_description} />
              <meta name="keywords" content={metaData.data.attributes.keywords} />
              <meta property="og:title" content={metaData.data.attributes.meta_title} />
              <meta property="og:description" content={metaData.data.attributes.meta_description} />
            </Helmet>
            <Container maxWidth="xl" sx={Styles.mainDivStyle}>
              {selectedLanguage == "en" ?
              <>
              <Typography
                variant="h4"
                gutterBottom
                style={Styles.HeadingStyle}
                dangerouslySetInnerHTML={{
                  __html: this.state.termsCondtiondata[0]?.attributes?.title,
                }}
              />

              <Typography variant="body1" paragraph>

                <div dangerouslySetInnerHTML={{ __html: this.state.termsCondtiondata[0]?.attributes?.description }} />
              </Typography>

              </>
               :
               <>
               <Typography
                variant="h4"
                gutterBottom
                style={Styles.HeadingStyle}
                dangerouslySetInnerHTML={{
                  __html: this.state.termsCondtiondata[0]?.attributes?.title_ar,
                }}
              />

              <Typography variant="body1" paragraph>

                <div dangerouslySetInnerHTML={{ __html: this.state.termsCondtiondata[0]?.attributes?.description_ar }} />
              </Typography>
               </>
               }

            </Container>
          </ThemeProvider>
        </NavigationMenu>
      </div>
      // Customizable Area End
    );
  }
}
// Customizable Area Start

const Styles = {
  DividerStyle: {
    background: "#376FD0",
    height: "2px",
    marginTop: "30px",
  },
  HeadingStyle: {
    fontWeight: 700,
    fontSize: "28px",
  },
  SubHeadingStyle: {
    fontWeight: 700,
    fontSize: "20px",
    margin: "20px 0px",
  },
  ParaStyle: {
    fontSize: "16px",
    fontWeight: 400,
    marginBottom: "20px",
  },
  mainDivStyle: {
    marginTop: { xs: "145px", md: "174px" },
    padding: { xs: "", lg: "0px 100px", xl: "0px 220px" },
  },
}
// Customizable Area End
