// Customizable Area Start
import React from "react";

import LandingPageController, {
    Props,
} from "./LandingPageController";
import {
    Box,
    Typography,
    TextField,
    MenuItem,
    Select,
    FormControl,
    styled,
    InputAdornment,
} from "@mui/material";
import {
    man_Img, women_Img, image_first, image_second, image_third, image_forth, image_fifth,
    image_sixth, arrowDown, arrowUp, coin_image, key_image, arrowOpen, arrowClose, 
} from "./assets";
import { Formik } from "formik";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import NavigationMenu from "../../../../packages/blocks/navigationmenu/src/NavigationMenu.web";
import xss from 'xss';
import { getInterFontByLanguage, getPoppinsFontByLanguage, getTextAlignByLanguage, translate, getDirectionLeftByLanguage, getFontSizeByLanguage } from "../../../../packages/components/src/Translate";
import { Helmet } from 'react-helmet';
import Loader from "../../../../packages/components/src/Loader.web";
const MainWrapper = styled(Box)(({theme}) => ({
    "& .mobileMainWrapper": {
        display:"flex",
        width:"100vw",
        flexDirection:"column" as "column",
        paddingBottom:"5px",
    },
    "& .thirdContainer": {
        display: "flex",
        width: "100vw",
        paddingTop: "3vw",
        marginTop: "100px",
        "@media (max-width: 899px)": {
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: "12vw",
        },

        "@media (max-width: 600px)": {
            paddingTop: "15vw",
        },
    },
    
    "& .thirdBoxCoin":{
        [theme.breakpoints.down(1201)]: {
            width: "8.5vw"
        },
        [theme.breakpoints.down(900)]: {
            display:"none"
        },
            width:"11.5vw",
            paddingTop:"13vw"
    },
" & .thirdBoxCoinImg": {
    overflow:"hidden",
    [theme.breakpoints.down(900)]: {
        display: "none",
    },
    width:"19vw",
    height:"30vw"

},
" & .thirdLeftBox": {
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent:"center",
    alignItems:"center",
    width: "46vw",
    [theme.breakpoints.down(900)]: {
        width: "80vw",
    },
},
" & .thirdBoxContent": {
    display: "flex",
    flexDirection: "column" as "column",
    [theme.breakpoints.down(900)]: {
        width:"100vw",
        justifyContent:"center",
        alignItems:"center"
    },
},
" & .thirdBoxTypos": {
    display: "flex",
    flexDirection: "column" as "column",
},
"& .thirdBoxTypoOne": {
   textAlign: getTextAlignByLanguage(),
   fontFamily: getPoppinsFontByLanguage(),
    fontSize: "55px",
    fontWeight: 600,
    lineHeight: "5vw",
    color: "rgba(11, 9, 10, 1)",
    "@media (max-width: 1200px)": {
        fontSize: "50px",
        lineHeight: "6vw",
    },
    "@media (max-width: 768px)": {
        fontSize: "40px",
        lineHeight: "7vw",
        textAlign: "center",
    padding:"0px 20px",

    },
    "@media (max-width: 480px)": {
        fontSize: "30px",
    padding:"0px 20px",

        lineHeight: "8vw",
    },
},

" & .inputMonths": {
    fontFamily: getPoppinsFontByLanguage(),
    fontSize: "15px",
    fontWeight: 700,
    color:"rgba(69, 69, 69, 1)",
  "& .css-qiwgdb":{
    padding:'1vw 0.7vw !important',
    display:"flex",
    justifyContent:"flex-start",
    alignItems:"center",
    [theme.breakpoints.down(1800)]: {
        fontSize: "0.85vw !important",
        padding: "1vw 32px !important",
    },
    [theme.breakpoints.down(1400)]: {
        fontSize: "1vw !important",
        padding: "1.25vw 28px !important",
    },
    [theme.breakpoints.down(1200)]: {
        fontSize: "1vw !important",
        padding: "1.43vw 28px !important",
    },
    [theme.breakpoints.down(900)]: {
        fontSize: "2.8vw !important",
        padding: "1.55vw 32px !important",
    },
    [theme.breakpoints.down(600)]: {
        fontSize: "3vw !important",
        padding: "3.4vw 32px !important",
    },
    [theme.breakpoints.down(450)]: {
        fontSize: "3vw !important",
        padding: "3.3vw 32px !important",
    },
   },
},
"& .thirdBoxTypoTwo": {
    fontFamily: getPoppinsFontByLanguage(),
    textAlign: getTextAlignByLanguage(),
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "2.86vw",
    color: "rgba(0, 0, 0, 1)",
    paddingBottom: "3.55vw",
     [theme.breakpoints.down(1200)]: {
        fontSize: "20px",
    },
    [theme.breakpoints.down(900)]: {
         fontSize: "14px",
        textAlign: "center",
        lineHeight: "5.86vw",
        padding: "10px 10px",
        fontWeight: 400,
        color: "#57585A",

    },
},

" & .thirdCalculate": {
    background: "rgba(29, 54, 187, 1)",
    padding: "0.82vw 0.6vw 0 0.6vw",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column" as "column",
    [theme.breakpoints.down(900)]: {
        width:"90vw",
        padding:"2.5vw 0"
    },
},
" & .thirdCalculateText": {
    fontFamily: getPoppinsFontByLanguage(),
    textAlign: getTextAlignByLanguage(),
        fontSize: "18px",
    fontWeight: 700,
    color: "rgba(255, 255, 255, 1)",
    paddingBottom: "1.64vw",
    [theme.breakpoints.down(900)]: {
        fontSize: "18px",
        textAlign: "center" as "center",
        paddingBottom:"4vw",
    },
},
" & .thirdRightBox": {
    height:"596px",
    width:"1022.3px",
    display:'flex',
    justifyContent:"center",
    alignItems:"center",
    [theme.breakpoints.down(900)]: {
        width:"90vw",
        paddingTop:"1px",
        position:"relative",
        bottom:0,
        height:"400px",
    },
},
"& .thirdRightBoxImg": {
    width:"590px",
    marginTop: "30%",
    height:"500px",
    objectFit:'contain',
    [theme.breakpoints.down(900)]: {
        position:"absolute",
        zIndex: -1,
        bottom: "0px",
        width: "300px",

    },
},
" & .forthContainer": {
    display: "flex",
    flexDirection: "row" as "row",
    width: "100vw",
    padding: "4.92vw",
    paddingLeft: "12.1vw",
    background: "rgba(247, 247, 247, 1)",
    [theme.breakpoints.down(900)]: {
        flexDirection: "column" as "column",
        padding:"1vw",
        width: "99vw",
    },
},
" & .forthContLeft": {
    display: "flex",
    flexDirection: "column" as "column",
    width: "50vw",
    [theme.breakpoints.down(900)]: {
        width: "100vw",
        justifyContent:"center",
        alignItems:"center", 
        padding:"7vw"   
    },
},
" & .forthLeftTypoOne": {
    fontFamily: getPoppinsFontByLanguage(),
    fontSize: "3.01vw",
    fontWeight: 700,
    lineHeight: "5vw",
    color: "rgba(0, 0, 0, 1)",
    width: "35.14vw",
    paddingBottom: "2.19vw",
    [theme.breakpoints.down(900)]: {
        fontSize: "3.5vw",
        lineHeight: "6vw",
        textAlign:"center",
        width: "90vw",
    },
},
" & .forthLeftTypoTwo": {
    fontFamily: getPoppinsFontByLanguage(),
    fontSize: "1.42vw",
    fontWeight: 400,
    lineHeight: "2.3vw",
    color: "rgba(0, 0, 0, 1)",
    width: "38.33vw",
    [theme.breakpoints.down(900)]: {
        fontSize: "2.42vw",
        fontWeight: 400,
        lineHeight: "3.8vw",
        color: "rgba(0, 0, 0, 1)",
        textAlign:"center",
        width: "90vw",
    },
},
" & .forthContRight": {
    width: "40vw",
    alignItems: "center",
    justifyContent: "flex-start",
    display: "flex",
    [theme.breakpoints.down(900)]: {
        width: "100vw", 
        justifyContent: "center",
    },
},
" & .forthContRightImg": {
    width: "450px",
    height: "450px",
    marginLeft:"100px",
    objectFit:'contain',
    [theme.breakpoints.down(900)]: {
        width:"40vw",
        height:"40vw"
    },
},
" & .fifthContainer": {
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "0.54vw 0.54vw 2.73vw 0.54vw",
},
" & .fifthTitle": {
    fontFamily: getPoppinsFontByLanguage(),
    fontSize: "2.9vw",
    fontWeight: 700,
    lineHeight: "4.38vw",
    color: "rgba(0, 0, 0, 1)",
    textAlign: "center" as "center",
    padding: "2.73vw",
    paddingTop: "4.65vw",
},
" & .fifthSubCon": {
    display: "flex",
    flexDirection: "row" as "row",
    position: "relative" as "relative",
    [theme.breakpoints.down(900)]: {
        flexDirection: "column" as "column",
    },
},
" & .fifthBox": {
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    alignItems: "center",
    height: "23vw",
    gap: "1vw",
    [theme.breakpoints.down(900)]: {
        height: "30vw",
        paddingBottom:"10vw",
    },
},
" & .fifthBoxFirst": {
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    alignItems: "center",
    height: "23vw",
    paddingBottom:"4.6vh",
    paddingTop:"2vh",
    gap: "1vw",
    [theme.breakpoints.down(900)]: {
        height: "30vw",
        paddingBottom:"10vw",
    },
},
" & .fifthMainImg": {
    height: "6.24vw",
    width: "6.24vw",
    display: "flex",
    alignSelf: "center",
    [theme.breakpoints.down(900)]: {
        height: "15vw",
        width: "15vw",
    },
},
" & .fifthSubImg": {
    height: "1.64vw",
    width: "1.64vw",
    borderRadius: "50%",
    background: "rgba(25, 25, 25, 1)",
    margin: "0.82vw",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    [theme.breakpoints.down(900)]: {
        display:"none"
    },    
},
" & .fifthNumber": {
    fontFamily: getPoppinsFontByLanguage(),
    fontSize: "1.31vw",
    fontWeight: 700,
    color: "rgba(255, 255, 255, 1)",
    textAlign: "center" as "center",
    [theme.breakpoints.down(900)]: {
        display:"none"
    },
},
" & .fifthHeading": {
    fontFamily: getPoppinsFontByLanguage(),
    fontSize: "1.31vw",
    fontWeight: 700,
    color: "rgba(0, 0, 0, 1)",
    textAlign: "center" as "center",
    width: "26.72vw",
    [theme.breakpoints.down(900)]: {
        width:"70vw",
        fontSize: "2.9vw",
        lineHeight:"6vw",
    }
},
" & .fifthSubHeading": {
    fontFamily: getPoppinsFontByLanguage(),
    fontSize: "0.87vw",
    fontWeight: 400,
    color: "rgba(0, 0, 0, 1)",
    textAlign: "center" as "center",
    width: "22.07vw",
    lineHeight:"2.65vh",
    [theme.breakpoints.down(900)]: {
        fontSize: "1.9vw",
        width: "70vw",
        lineHeight:"3vw",
    }
},
" & .fifthContainerAr": {
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "0.54vw 0.54vw 2.73vw 0.54vw",
    direction:"rtl"
},
" & .fifthTitleAr": {
    fontFamily: getPoppinsFontByLanguage(),
    fontWeight: 700,
    fontSize: "2.9vw",
    color: "rgba(0, 0, 0, 1)",
    textAlign: "center" as "center",
    lineHeight: "4.38vw",
    padding: "2.73vw",
    paddingTop: "4.65vw",
},
" & .fifthSubConAr": {
    flexDirection: "row" as "row",
    position: "relative" as "relative",
    display: "flex",
    [theme.breakpoints.down(900)]: {
        flexDirection: "column" as "column",
    },
},
" & .fifthBoxAr": {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column" as "column",
    alignItems: "center",
    height: "23vw",
    gap: "1vw",
    [theme.breakpoints.down(900)]: {
        paddingBottom:"10vw",
        height: "30vw",
    },
},
" & .fifthBoxFirstAr": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column" as "column",
    height: "23vw",
    paddingBottom:"4.6vh",
    paddingTop:"2vh",
    gap: "1vw",
    [theme.breakpoints.down(900)]: {
        paddingBottom:"10vw",
        height: "30vw",
    },
},
" & .fifthMainImgAr": {
    height: "6.24vw",
    alignSelf: "center",
    display: "flex",
    width: "6.24vw",
    [theme.breakpoints.down(900)]: {
    height: "15vw",
        width: "15vw",
    },
},
" & .fifthSubImgAr": {
    width: "1.64vw",
    borderRadius: "50%",
    height: "1.64vw",
    background: "rgba(25, 25, 25, 1)",
    display:"flex",
    margin: "0.82vw",
    justifyContent:"center",
    alignItems:"center",
    [theme.breakpoints.down(900)]: {
        display:"none"
    },    
},
" & .fifthNumberAr": {
    fontFamily: getPoppinsFontByLanguage(),
    fontWeight: 700,
    color: "rgba(255, 255, 255, 1)",
    fontSize: "1.31vw",
    textAlign: "center" as "center",
    [theme.breakpoints.down(900)]: {
        display:"none"
    },
},
" & .fifthHeadingAr": {
    fontSize: "1.31vw",
    fontWeight: 700,
    color: "rgba(0, 0, 0, 1)",
    fontFamily: getPoppinsFontByLanguage(),
    textAlign: "center" as "center",
    width: "26.72vw",
    [theme.breakpoints.down(900)]: {
        width:"70vw",
        fontSize: "2.9vw",
        lineHeight:"6vw",
    }
},
" & .fifthSubHeadingAr": {
    fontFamily: getPoppinsFontByLanguage(),
    fontSize: "0.87vw",
    color: "rgba(0, 0, 0, 1)",
    textAlign: "center" as "center",
    fontWeight: 400,
    width: "22.07vw",
    lineHeight:"2.65vh",
    [theme.breakpoints.down(900)]: {
        fontSize: "1.9vw",
        lineHeight:"3vw",
        width: "70vw",
    }
},
"& .buttonSubmitAppTwo": {
  background: "rgba(29, 54, 187, 1)",
  fontFamily: getPoppinsFontByLanguage(),
  color: "rgba(255, 255, 255, 1)",
  fontSize: "1.09vw", 
  fontWeight: 700,
  cursor:"pointer",
  borderRadius: "8px",
  width: "350px", 
   border: "none",
  padding:"10px",
  
  "@media (max-width: 768px)": { 
    fontSize: "17px", 
    width: "276px", 
  },

  "@media (max-width: 480px)": { 
    fontSize: "5vw", 
    width: "90%", 
   },
},
" & .thirdCalculateTabs": {
    display: "flex",
    flexDirection: "row" as "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "0.54vw",
    [theme.breakpoints.down(900)]: {
        flexDirection: "column" as "column",
        gap: "2vw",
    },
},
" & .labels": {
    fontSize: "0.76vw",
    fontFamily: getPoppinsFontByLanguage(),
    textAlign:getTextAlignByLanguage(),
    fontWeight: 400,
    color: "rgba(255, 255, 255, 1)",
    [theme.breakpoints.down(900)]: {
        fontSize: "2.7vw",
    },
},
"& .labelsDurations": {
    fontSize: "14.6px", 
    fontFamily: getPoppinsFontByLanguage(),
    fontWeight: 400,
    color: "rgba(255, 255, 255, 1)",
    "@media (max-width: 900px)": {
      fontSize: "24.3px", 
    },
  },
" & .labels_when": {
    fontSize: "0.76vw",
    fontFamily: getPoppinsFontByLanguage(),
    fontWeight: 400,
    color: "rgba(29, 54, 187, 1)",
    [theme.breakpoints.down(900)]: {
        fontSize: "2.7vw",
    },
},
" & .input": {
    background: "rgba(255, 255, 255, 1)",
    borderRadius: "6px",
    padding:0,
    color: "rgba(107, 107, 107, 1)",
    fontSize: "0.87vw",
    fontFamily: getInterFontByLanguage(),
    textAlign:getTextAlignByLanguage(),
    fontWeight: 400,
    width: "100%",
    [theme.breakpoints.down(900)]: {
        fontSize: "1.2vw",
        padding:0
    },
    "& .MuiInputBase-input::placeholder": {
        color: "rgba(107, 107, 107, 1)",
        fontWeight: 400,
        opacity: 1
    },
},
" & .inputMon": {
    position: 'relative' as 'relative',
    alignItems:"center",
    display: 'flex',
    borderRadius:"6px",
    background:"rgba(255,255,255,1)",
    justifyContent: 'center',
    height:'3.4vw',
    [theme.breakpoints.up(1400)]: {
        height:'3.44vw'
    },
    [theme.breakpoints.down(1401)]: {
        height:'3.94vw'
    },
    [theme.breakpoints.down(1200)]: {
        height:'4.3vw'
    },
    [theme.breakpoints.down(900)]: {
        height:'7.2vw'
    },
    [theme.breakpoints.down(600)]: {
        height:'11.2vw',
    },
},
" & .calenderIcon":{
    pointerEvents:"none",
    position:"absolute",
    right:5,
    width:"1.5vw",
    height:"1.5vw",
    backgroundColor:'white',
    [theme.breakpoints.down(1300)]: {
        width:"1.6vw",
        height:"1.6vw",  
    },
    [theme.breakpoints.down(1200)]: {
        width:"2vw",
        height:"2vw",  
    },
    [theme.breakpoints.down(900)]: {
        width:"4vw",
        height:"4vw",  
    },
    [theme.breakpoints.down(700)]: {
        width:"4.5vw",
        height:"4.5vw", 
    },
    [theme.breakpoints.down(600)]: {
        width:"5.5vw",
        height:"5.5vw", 
    },
    [theme.breakpoints.down(450)]: {
        width:"5.5vw",
        height:"5.5vw", 
    },
    [theme.breakpoints.down(350)]: {
        width:"5.5vw",
        height:"5.5vw", 
    },
},

" & .calculateButton": {
    cursor:"pointer",
    textTransform: "none" as "none",
    fontFamily: getPoppinsFontByLanguage(),
    background: "rgba(242, 242, 247, 1)",
    color: "rgba(29, 54, 187, 1)",
    fontSize: "1.09vw",
    fontWeight: 700,
    borderRadius: "6px",
    height: "3.44vw",
    // width: "10.56vw",
    border:"none",
    [theme.breakpoints.up(1400)]: {
        height:'3.44vw'
    },
    [theme.breakpoints.down(1401)]: {
        height:'3.94vw'
    },
    [theme.breakpoints.down(1200)]: {
        height:'4.3vw'
    },
    [theme.breakpoints.down(900)]: {
        height:'7.2vw',
        fontSize:'3.09vw'
    },
    [theme.breakpoints.down(600)]: {
        height:'11.2vw',
    },
},
" & .firstField": { 
    width: "14.67vw",
    padding:0,
    color: "rgba(107, 107, 107, 1)",
    fontWeight:400,
    display:"flex",
    flexDirection:"column",
    gap:"0.1vw",
    [theme.breakpoints.down(900)]: {
        width:"85vw",
        padding:0,
    },
    "& .MuiOutlinedInput-notchedOutline":{
        border:"none"
    },
    "& .MuiOutlinedInput-input": {
        fontWeight:700,
        color: "rgba(69, 69, 69, 1)",
        [theme.breakpoints.up(1400)]: {
            padding: "1vw 1vw 1vw 0",
            fontSize: "1vw",
        },
        [theme.breakpoints.down(1400)]: {
            padding: "1.25vw 1vw 1.25vw 0",
            fontSize: "1vw",
        },
        [theme.breakpoints.down(1200)]: {
            padding: "1.4vw 1vw 1.4vw 0",
            fontSize: "1vw",
        },
        [theme.breakpoints.down(900)]: {
        padding: "1.5vw 1vw 1.5vw 0",
        fontSize: "3vw",
        }, 
        [theme.breakpoints.down(600)]: {
            padding: "3.5vw 1vw 3.5vw 0",
            fontSize: "3vw",
        },
        [theme.breakpoints.down(600)]: {
            padding: "3.5vw 1vw 3.5vw 0",
            fontSize: "3vw",
        },    
    },
    "& .MuiInputAdornment-root":{
        [theme.breakpoints.up(1200)]: {
            fontSize: "1vw",
            },
        [theme.breakpoints.down(1200)]: {
            fontSize: "1vw",
            },
        [theme.breakpoints.down(900)]: {
            fontSize: "3vw",
        },     }
 },
" & .secondField": { 
    width: "14.67vw",
    fontWeight:400,
    color: "rgba(107, 107, 107, 1)",
    display:"flex",
    flexDirection:"column",
    gap:"0.1vw",
    "& .MuiInputBase-input": {
        padding: "1vw",
        [theme.breakpoints.down(900)]: {
            padding: "2vh 2vw",
            fontSize: "2vw",
            },
    },
    [theme.breakpoints.down(900)]: {
        width:"85vw",
        padding:0
    },
 },
" & .thirdField": { 
    display:"flex",
    flexDirection:"column",
    gap:"0.1vw",
    width: "9.7vw" ,
    "& .MuiOutlinedInput-notchedOutline":{
        border:"none",
    },
    "& .MuiFormControl-root": {
        padding: 0,
    },
    [theme.breakpoints.down(900)]: {
        width:"85vw"
    },
},
" & .buttonCalBox": { 
    width: "10.56vw",
    display:"flex",
    flexDirection:"column",
    gap:"0.1vw",
    [theme.breakpoints.down(900)]: {
        width:"85vw"
    },
 },
" & .sixthContainer": {
    display: "flex",
    width:"100vw",
    marginTop:"6vw",
    justifyContent: "center",
    paddingBottom:"3vw",
    alignItems:"center",
    [theme.breakpoints.down(900)]: {
        paddingLeft: 0,
        position:"relative",
        paddingBottom:"10vw",
    },
},
" & .sixthMain": {
    alignItems:"center",
    justifyContent:"center",
    display: "flex",
    flexDirection: "column" as "column",
    width: "64vw",
    [theme.breakpoints.down(900)]: {
        width: "90vw",
     }
},
" & .sixthContHeading":{
    color: "rgba(0, 0, 0, 1)",
    fontSize:"3.17vw",
    fontFamily: getPoppinsFontByLanguage(),
    fontWeight:700,
    textAlign:"left" as "left",
    padding:"3.5vw 2vw",
    [theme.breakpoints.down(900)]: {
       width:"40vw",
       lineHeight:"5vw",
       textAlign:"center" as "center",
    },
  },
  " & .sixthFAQbox":{
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center",
     [theme.breakpoints.down(900)]: {
        width:"100vw",
        display:"flex",
        flexDirection:"column",
        justifyContent:"center",
        alignItems:"center",
     },
  },
  " & .sixthQuesBox":{
    border: "1px solid rgba(209, 209, 214, 1)",
    borderRadius:"8px",
    background: "rgba(255, 255, 255, 1)",
    width:"48vw",
    padding:"1.09vw",
    margin:"1.09vw",
    display:"flex",
    flexDirection:"column" as "column",
    [theme.breakpoints.down(900)]: {
        width:"90%",
        zIndex:2,
     },
  },
  " & .sixthBox":{
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center",
    flexDirection: getDirectionLeftByLanguage(),
    [theme.breakpoints.down(900)]: {
    },
  },
  " & .sixthContQuestions":{
    color: "rgba(0, 0, 0, 1)",
    fontSize:"0.93vw",
    fontFamily: getPoppinsFontByLanguage(),
    textAlign:getTextAlignByLanguage(),
    width:'100%',
    fontWeight:700,
    [theme.breakpoints.down(900)]: {
        fontSize:"2vw",
    },
  },
  " & .faqAns":{
    textAlign:getTextAlignByLanguage(),
    color: "rgba(0, 0, 0, 1)",
    fontSize:"0.93vw",
    fontFamily: getPoppinsFontByLanguage(),
    fontWeight:400,
    [theme.breakpoints.down(900)]: {
        fontSize:"2vw",
        width:"56vw"
  }},
  " & .sixthContRight":{
    width:"11vw",
    position: "absolute",
    right: 0,
    overflow:"hidden",
    zIndex:-2,
    [theme.breakpoints.down(900)]: {
        top:"65%",
        width:"18vw",
        right:0
    },
  },
  " & .sixthContRightImg":{
    width:"14vw",
    height:"20vw",
    [theme.breakpoints.down(900)]: {
        width:"22vw",
        height:"30vw",
    },
  },
  " & .arrowOpen":{
    width:"2vw",
    padding:"0.38vw",
    height:"2.36vw",
    [theme.breakpoints.down(900)]: {
        width:"3vw",
        height:"4.36vw",
    },
  },
  " & .arrowClose":{height:"1.36vw",
    padding:"0.38vw",
    width:"1.36vw",
  },
  " & .arrowDown":{
    [theme.breakpoints.down(900)]: {
        display:"none"
    },
    width:"15.75vw",height:"19.16vw",
    position:"absolute" as "absolute",left:"18vw",bottom:"8vw",
},
" & .arrowUp": {
    [theme.breakpoints.down(900)]: {
        display:"none"
    },
    width: "15.75vw", height: "19.16vw",
    position: "absolute" as "absolute", left: "46vw",bottom:"7vw",
},
" & .arrowCloseAr":{height:"1.36vw",
    padding:"0.38vw",
    width:"1.36vw",
  },
  " & .arrowDownAr":{ 
     transform: "rotate(180deg)",
    [theme.breakpoints.down(900)]: {
        display:"none"
    },
    width:"15.75vw",height:"19.16vw",
    position:"absolute" as "absolute",left:"18vw",bottom:"5vw",
},
" & .arrowUpAr": {
    transform: "rotate(180deg)",
    [theme.breakpoints.down(900)]: {
        display:"none"
    },
    width: "15.75vw", height: "19.16vw",
    position: "absolute" as "absolute", left: "46vw",bottom:"7vw",
},
" & .form":{
    display :"flex",
    flexDirection: "row" as "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    gap: "0.54vw",
    [theme.breakpoints.down(900)]: {
        flexDirection: "column" as "column",
        gap: "2vw",
    },
} ,
" & .inputDateBox": {
    background: "rgba(255, 255, 255, 1)",
    borderRadius: "6px",
    padding:"1.8vh 0",
    color: "rgba(107, 107, 107, 1)",
    fontSize: "0.87vw",
    fontFamily: getPoppinsFontByLanguage(),
    fontWeight: 400,
    width: "100%",
    display:"flex",
    justifyContent:"flex-end",
    paddingRight:"0.7vw",
    position:"relative",
    [theme.breakpoints.down(900)]: {
        fontSize: "1.2vw",
        padding:"1.7vh 0",
        paddingRight:"2vw",
    },
},
"& .dateInput": {
    position: 'relative' as 'relative',
    display: 'flex',
    alignItems:"center",
    background:"rgba(255,255,255,1)",
    borderRadius:"6px",
    justifyContent: 'space-between',
    height:'3.4vw',
    [theme.breakpoints.up(1400)]: {
        height:'3.44vw',
    },
    [theme.breakpoints.down(1400)]: {
        height:'3.94vw',
    },
    [theme.breakpoints.down(1200)]: {
        height:'4.3vw',
    },
    [theme.breakpoints.down(900)]: {
        height:'7.2vw',
    },
    [theme.breakpoints.down(600)]: {
        height:'11.2vw',
    },
  },
  " & .inputDateClass": {
    position: 'absolute',
    width: '10vw',
    fontSize:"1vw",
    color: "rgba(69, 69, 69, 1)",
    fontWeight:700,
    left: "0.3vw",
    background: "rgba(255, 255, 255, 1)",
    paddingLeft:"1vw",
    [theme.breakpoints.down(1400)]: {
        width:"12vw"
    },
    [theme.breakpoints.down(900)]: {
        width:"20vw",
        fontSize:"2.5vw",
    },
    [theme.breakpoints.down(700)]: {
        width:"22vw",
        fontSize:"3vw",
    },
    [theme.breakpoints.down(600)]: {
        width:"24vw",
        fontSize:"3vw",
    },
    [theme.breakpoints.down(450)]: {
        width:"33vw",
        fontSize:"3.5vw",
    },
    [theme.breakpoints.down(450)]: {
        width:"40vw",
        fontSize:"3vw",
    },
},
" & .dateFormatTypo":{
    position: 'absolute',
    width: '10vw',
    left: "0.3vw",
    top: "-0.7vw",
    fontSize:"1vw",
    lineHeight:"1.4vw",
    color: "rgba(107, 107, 107, 1)",
    fontWeight:700,
    background: "rgba(255, 255, 255, 1)",
    paddingLeft:"1vw",
    [theme.breakpoints.down(1400)]: {
        width:"12vw"
    },
    [theme.breakpoints.down(900)]: {
        fontSize:"2vw",
        width:"20vw",
        top:"-1.4vw",
        lineHeight:"3vw",
    },
    [theme.breakpoints.down(700)]: {
        fontSize:"2.4vw",
        width:"22vw",
        lineHeight:"3.4vw",
    },
    [theme.breakpoints.down(600)]: {
        width:"24vw",
        fontSize:"3vw",
        lineHeight:"3.7vw",
    },
    [theme.breakpoints.down(450)]: {
        fontSize:"3.5vw",
        width:"33vw",
        lineHeight:"4vw",
    },
    [theme.breakpoints.down(450)]: {
        width:"40vw",
    },
},
" & .inputDateSubClass" :{
    width: '100%',
    height:"100%",
    borderRadius:"6px",
    padding:"0px 10px",
    textAlign:getTextAlignByLanguage(),
    fontFamily: getPoppinsFontByLanguage(),
    fontSize:getFontSizeByLanguage()
},  
" & .errorMsg":{
    height:"8px",
    fontSize:"0.8vw",
    fontWeight:600,
    color:"red",
    [theme.breakpoints.down(900)]: {
        fontSize:"1.8vw",
    },
}    
  }))
// Customizable Area End

export default class LandingPage extends LandingPageController {
    // Customizable Area Start
    // Customizable Area End
    
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderStepBoxAr = () => {
        return (
            <Box>
                <Box className="fifthContainerAr">
                    <Typography className="fifthTitleAr">{translate("howItWorksTitleLandingPage")}</Typography>
                    <Box className="fifthSubConAr">
                        <Box className="fifthBoxFirstAr">
                            <img className="fifthMainImgAr" src={image_first.default} />
                            <Box className="fifthSubImgAr">
                                <Typography className="fifthNumberAr">1</Typography>
                            </Box>
                            <Typography className="fifthHeadingAr">{translate("step1HeadingLandingPage")}</Typography>
                            <Typography className="fifthSubHeadingAr">{translate("step1DescriptionLandingPage")}</Typography>
                        </Box>
                        <img className="arrowDownAr" src={arrowDown.default} />
                        <Box className="fifthBoxAr">
                            <img className="fifthMainImgAr" src={image_second.default} />
                            <Box className="fifthSubImgAr">
                                <Typography className="fifthNumberAr">2</Typography>
                            </Box>
                            <Typography className="fifthHeadingAr">{translate("step2HeadingLandingPage")}</Typography>
                            <Typography className="fifthSubHeadingAr">{translate("step2DescriptionLandingPage")}</Typography>
                        </Box>
                        <img className="arrowUpAr" src={arrowUp.default} />
                        <Box className="fifthBoxAr">
                            <img className="fifthMainImgAr" src={image_third.default} />
                            <Box className="fifthSubImgAr">
                                <Typography className="fifthNumberAr">3</Typography>
                            </Box>
                            <Typography className="fifthHeadingAr">{translate("step3HeadingLandingPage")}</Typography>
                            <Typography className="fifthSubHeadingAr">{translate("step3DescriptionLandingPage")}</Typography>
                        </Box>
                    </Box>
                    <Box className="fifthSubConAr">
                        <Box className="fifthBoxAr">
                            <img className="fifthMainImgAr" src={image_forth.default} />
                            <Box className="fifthSubImgAr">
                                <Typography className="fifthNumberAr">4</Typography>
                            </Box>
                            <Typography className="fifthHeadingAr">{translate("step4HeadingLandingPage")}</Typography>
                            <Typography className="fifthSubHeadingAr">{translate("step4DescriptionLandingPage")}</Typography>
                        </Box>
                        <img className="arrowDownAr" src={arrowDown.default} />
                        <Box className="fifthBoxAr">
                            <img className="fifthMainImgAr" src={image_fifth.default} />
                            <Box className="fifthSubImgAr">
                                <Typography className="fifthNumberAr">5</Typography>
                            </Box>
                            <Typography className="fifthHeadingAr">{translate("step5HeadingLandingPage")}</Typography>
                            <Typography className="fifthSubHeadingAr">{translate("step5DescriptionLandingPage")}</Typography>
                        </Box>
                        <img className="arrowUpAr" src={arrowUp.default} />
                        <Box className="fifthBoxAr">
                            <img className="fifthMainImgAr" src={image_sixth.default} />
                            <Box className="fifthSubImgAr">
                                <Typography className="fifthNumberAr">6</Typography>
                            </Box>
                            <Typography className="fifthHeadingAr">
                                {translate("step6HeadingLandingPage")}
                            </Typography>
                            <Typography className="fifthSubHeadingAr">
                                {translate("step6DescriptionLandingPage")}
                            </Typography>
                        </Box>
                    </Box>
                    <button onClick={this.handleSubmitApplication}
                        className="buttonSubmitAppTwo">
                        {translate("submitApplicationButtonLandingPage")}
                    </button>
                </Box>

                <Box className="sixthContainer">
                    <Box className="sixthMain">
                        <Typography
                            className="sixthContHeading"
                        >
                            {translate("frequentlyAskedQuestionsHeadingLandingPage")}
                        </Typography>
                        <Box className="sixthFAQbox">
                            {this.state.FaqData.map((faq: { id: string, attributes: { question: string, answer: string, question_ar: string, answer_ar: string } }, index: number) => (
                                <Box key={faq.id} className="sixthQuesBox">
                                    <Box className="sixthBox">
                                       
                                            <Typography className="sixthContQuestions" dangerouslySetInnerHTML={{ __html: xss(faq.attributes.question_ar || "") }}></Typography>

                                        <img className="arrowOpenAr"
                                            data-test-id={`faq${index}`}
                                            src={this.state.openIndex === index ? arrowClose.default : arrowOpen.default}
                                            onClick={() => this.handleFaqToggle(index)} />
                                    </Box>
                                   <>
                                        {this.state.openIndex === index &&
                                            <Typography className="faqAns" dangerouslySetInnerHTML={{ __html: xss(faq.attributes.answer_ar || "") }}></Typography>
                                        }
                                    </>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                    <Box className="sixthContRight">
                        <img className="sixthContRightImg" src={key_image.default} />
                    </Box>
                </Box>
            </Box>
        )
    }

    renderStepBoxEn = () => {
        return (
            <Box>
                <Box className="fifthContainer">
                    <Typography className="fifthTitle">{translate("howItWorksTitleLandingPage")}</Typography>
                    <Box className="fifthSubCon">
                        <Box className="fifthBoxFirst">
                            <img className="fifthMainImg" src={image_first.default} />
                            <Box className="fifthSubImg">
                                <Typography className="fifthNumber">1</Typography>
                            </Box>
                            <Typography className="fifthHeading">{translate("step1HeadingLandingPage")}</Typography>
                            <Typography className="fifthSubHeading">{translate("step1DescriptionLandingPage")}</Typography>
                        </Box>
                        <img className="arrowDown" src={arrowDown.default} />
                        <Box className="fifthBox">
                            <img className="fifthMainImg" src={image_second.default} />
                            <Box className="fifthSubImg">
                                <Typography className="fifthNumber">2</Typography>
                            </Box>
                            <Typography className="fifthHeading">{translate("step2HeadingLandingPage")}</Typography>
                            <Typography className="fifthSubHeading">{translate("step2DescriptionLandingPage")}</Typography>
                        </Box>
                        <img className="arrowUp" src={arrowUp.default} />
                        <Box className="fifthBox">
                            <img className="fifthMainImg" src={image_third.default} />
                            <Box className="fifthSubImg">
                                <Typography className="fifthNumber">3</Typography>
                            </Box>
                            <Typography className="fifthHeading">{translate("step3HeadingLandingPage")}</Typography>
                            <Typography className="fifthSubHeading">{translate("step3DescriptionLandingPage")}</Typography>
                        </Box>
                    </Box>
                    <Box className="fifthSubCon">
                        <Box className="fifthBox">
                            <img className="fifthMainImg" src={image_forth.default} />
                            <Box className="fifthSubImg">
                                <Typography className="fifthNumber">4</Typography>
                            </Box>
                            <Typography className="fifthHeading">{translate("step4HeadingLandingPage")}</Typography>
                            <Typography className="fifthSubHeading">{translate("step4DescriptionLandingPage")}</Typography>
                        </Box>
                        <img className="arrowDown" src={arrowDown.default} />
                        <Box className="fifthBox">
                            <img className="fifthMainImg" src={image_fifth.default} />
                            <Box className="fifthSubImg">
                                <Typography className="fifthNumber">5</Typography>
                            </Box>
                            <Typography className="fifthHeading">{translate("step5HeadingLandingPage")}</Typography>
                            <Typography className="fifthSubHeading">{translate("step5DescriptionLandingPage")}</Typography>
                        </Box>
                        <img className="arrowUp" src={arrowUp.default} />
                        <Box className="fifthBox">
                            <img className="fifthMainImg" src={image_sixth.default} />
                            <Box className="fifthSubImg">
                                <Typography className="fifthNumber">6</Typography>
                            </Box>
                            <Typography className="fifthHeading">{translate("step6HeadingLandingPage")}</Typography>
                            <Typography className="fifthSubHeading">{translate("step6DescriptionLandingPage")}</Typography>
                        </Box>
                    </Box>
                    <button onClick={this.handleSubmitApplication} className="buttonSubmitAppTwo">
                        {translate("submitApplicationButtonLandingPage")}
                    </button>
                </Box>

                <Box className="sixthContainer">
                    <Box className="sixthMain">
                        <Typography
                            className="sixthContHeading">
                            {translate("frequentlyAskedQuestionsHeadingLandingPage")}
                        </Typography>
                        <Box className="sixthFAQbox">
                            {
                            this.state.FaqData?.map((faq: { id: string, attributes: { question: string, answer: string, question_ar: string, answer_ar: string } }, index: number) => (
                                <Box key={faq.id} className="sixthQuesBox">
                                    <Box className="sixthBox">
                                            <Typography className="sixthContQuestions" dangerouslySetInnerHTML={{ __html: xss(faq.attributes.question || "") }}></Typography>
                                        <img className="arrowOpen"
                                            data-test-id={`faq${index}`}
                                            src={this.state.openIndex === index ? arrowClose.default : arrowOpen.default}
                                            onClick={() => this.handleFaqToggle(index)} />
                                    </Box>
                                        {this.state.openIndex === index &&
                                            <Typography className="faqAns" dangerouslySetInnerHTML={{ __html: xss(faq.attributes.answer) }}></Typography>
                                        }                                   
                                </Box>
                            ))}
                        </Box>
                    </Box>
                    <Box className="sixthContRight">
                        <img className="sixthContRightImg" src={key_image.default} />
                    </Box>
                </Box>
            </Box>
        )
    }

    renderInput = (selecteLanguage: string) => {
        return (
            <Box>
                {selecteLanguage == "en" ?
                    <TextField
                        data-test-id="annualRent"
                        id={"annualRent"}
                        value={this.state.annualRent}
                        name="annualRent"
                        onChange={this.handleRentChange}
                        fullWidth
                        className="input"
                        placeholder="60,000"
                        InputProps={{
                            startAdornment: (<InputAdornment disableTypography position="start">
                                SAR
                            </InputAdornment>)
                        }}
                    /> :
                    <TextField
                        data-test-id="annualRent"
                        id={"annualRent"}
                        value={this.state.annualRent}
                        name="annualRent"
                        onChange={this.handleRentChange}
                        fullWidth
                        className="input"
                        placeholder="60,000"
                        InputProps={{
                            endAdornment: (<InputAdornment disableTypography position="start">
                                SAR
                            </InputAdornment>),
                            inputProps: {
                                style: { textAlign: 'end' }
                            },
                        }}
                    />
                }
            </Box>
        )
    }
    render() {
        const { metaData } = this.state;
         return (
            <MainWrapper data-test-id="mainWrapper">
                 <Helmet>
                     <meta charSet="utf-8" />
                     <title>{metaData.data.attributes.meta_title}</title>
                     <meta name="description" content={metaData.data.attributes.meta_description} />
                     <meta name="keywords" content={metaData.data.attributes.keywords} />
                     <meta property="og:title" content={metaData.data.attributes.meta_title} />
                     <meta property="og:description" content={metaData.data.attributes.meta_description} />
                     </Helmet>
                <ThemeProvider theme={theme}>
                    <div ref={this.firstDivRef}>
                        <NavigationMenu navigation={this.props.navigation} id=""isFooter={true} >
                            <div className="mobileMainWrapper">
                                <Box className="thirdContainer">
                                    <Box className="thirdBoxCoin">
                                        <img className="thirdBoxCoinImg" src={coin_image.default} />
                                    </Box>
                                    <Box className="thirdLeftBox">
                                        <Box className="thirdBoxContent">
                                            <Box className="thirdBoxTypos">
                                                <Typography className="thirdBoxTypoOne">
                                                {translate("rentNowPayLaterLandingPage")}
                                                </Typography>
                                                 <Typography className="thirdBoxTypoTwo">
                                                     {translate("titleLandingPage")}
                                                 </Typography>

                                             </Box>
                                            <Box className="thirdCalculate">
                                                <Typography className="thirdCalculateText">
                                                {translate("subtitleLandingPage")}
                                                    
                                                    </Typography>

                                                <Box className="thirdCalculateTabs">
                                                    <Formik
                                                    data-test-id="formik"
                                                        initialValues={{
                                                            annualRent: "",
                                                            moveInDate: "",
                                                            duration: ""
                                                        }}
                                                        onSubmit={(values, { setSubmitting }) => {
                                                            setSubmitting(false);
                                                        }}
                                                    >
                                                        {({
                                                            handleSubmit,
                                                            isSubmitting,
                                                            touched
                                                        }) => (
                                                            <form autoComplete="off" noValidate onSubmit={handleSubmit} className="form">
                                                                <Box className="firstField">
                                                                    <label className="labels">
                                                                    {translate("annualRentValueLandingPage")}                                                                        
                                                                        </label>
                                                                        {this.renderInput(this.state.selecteLanguage)}
                                                                    
                                                                     
                                                                            </Box>
                                                                 <Box className="secondField">
                                                                     <label className="labels">{translate("moveInDateLabelLandingPage")}</label>

                                                                     <Box className="dateInput">
                                                                         <input
                                                                             type="date"
                                                                             data-testID="get-new-date"
                                                                             value={this.state.moveInDate}
                                                                             onChange={this.handleDateChange}
                                                                             className="inputDateSubClass"
                                                                             min={new Date().toISOString().slice(0, 10)} 
                                                                             max="9999-12-31"
                                                                         />

                                                                     </Box>
                                                                 </Box>
                                                                 <Box className="thirdField">
                                                                     <label className="labels">{translate("durationLabelLandingPage")}</label>
                                                                     <FormControl className="inputMon" autoFocus={false}>
                                                                         <Select
                                                                             id={"duration"}
                                                                             autoFocus={false}
                                                                             value={this.state.duration}
                                                                             onChange={this.handleDurationChange}
                                                                             className="inputMonths"
                                                                             sx={{
                                                                                '& .MuiSelect-icon': { marginLeft: '10px' },
                                                                                '& .MuiSelect-select': { paddingRight: '32px !important' },
                                                                            }}
                                                                         >
                                                                             <MenuItem value="6 Months" className="labels">{translate("duration6MonthsLandingPage")}</MenuItem>
                                                                             <MenuItem value="12 Months" className="labels">{translate("duration12MonthsLandingPage")}</MenuItem>
                                                                         </Select>
                                                                     </FormControl>
                                                                 </Box>
                                                                 <Box className="buttonCalBox">
                                                                     <label className="labels_when">{translate("whenLabelLandingPage")}</label>
                                                                     <button
                                                                         onClick={this.handleSubmit}
                                                                         type="submit"
                                                                         disabled={isSubmitting}
                                                                         className="calculateButton"
                                                                     >
                                                                         {translate("calculateButtonLabelLandingPage")}
                                                                     </button>
                                                                 </Box>
                                                            </form>
                                                        )}
                                                    </Formik>    
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box className="thirdRightBox">
                                        <img className="thirdRightBoxImg" src={man_Img.default} />
                                    </Box>
                                </Box>

                                <Box className="forthContainer">
                                {this.state.selecteLanguage == "en" ?
                                    <Box className="forthContLeft">
                                         <Typography className="forthLeftTypoOne" dangerouslySetInnerHTML={{ __html: xss(this.state.whyChooseData?.title || "") }}></Typography>
                                         <Typography className="forthLeftTypoTwo" dangerouslySetInnerHTML={{ __html: xss(this.state.whyChooseData?.description || "") }}></Typography>
                                    </Box>
                                         : 
                                    <Box className="forthContLeft">
                                        <Typography className="forthLeftTypoOne" dangerouslySetInnerHTML={{ __html: xss(this.state.whyChooseData?.title_ar || "") }}></Typography>
                                        <Typography className="forthLeftTypoTwo" dangerouslySetInnerHTML={{ __html: xss(this.state.whyChooseData?.description_ar || "") }}></Typography>
                                    </Box>
                                }
                                    <Box className="forthContRight">
                                        <img className="forthContRightImg" src={women_Img.default} />
                                    </Box>
                                </Box>
                                <Box>
                                     {this.state.selecteLanguage === "ar" ? this.renderStepBoxAr() : this.renderStepBoxEn()}
                                 </Box>

                            </div>
                        </NavigationMenu>
                    </div>
                </ThemeProvider>
                 <Loader loading={this.state.isLoader} />

            </MainWrapper> 

        );
    }
    // Customizable Area End
}

// Customizable Area Start
const theme = createTheme({
    palette: {
      primary: {
        main: "#3f51b5",
      },
      secondary: {
        main: "#f50057",
      },
    },
    typography: {
      h6: {
        fontWeight: 500,
      },
      body1: {
        fontFamily: getPoppinsFontByLanguage(),
        fontSize: '18px',
        fontWeight: 400,
        lineHeight: '27px',
        textAlign: 'left',
      },
    },
  });
// Customizable Area End