import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";



interface ShariaComplianceAttributes {
  id: string;
  type: string;
  attributes: {
    title: string;
    description: string;
    title_ar: string;
    description_ar: string;
  };
}


interface APIPayloadType {
  contentType?: string;
  body?: object;
  method: string;
  endPoint: string;
  token?: string;
  type?: string;
}

interface ValidResponseType {
  data: {
    attributes: {
      page_name: string;
      meta_title: string;
      meta_description: string;
      keywords:string;
    }
  }
  message: string;
  meta: {
    message: string;
  }
};

interface MetaData {
  data: {
    attributes: {
      keywords:string; 
      page_name: string;
      meta_title: string;
      meta_description: string;
    }
  }
}

interface ShariaCompliance {
  id: string;
  type: string;
  attributes: ShariaComplianceAttributes;
}

interface ShariaComplianceState {
  attributes: any;
  shariaComplianceData: ShariaCompliance; 
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: object;
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  ShariaCompliancedata:ShariaComplianceState[];
  selectLanguage:string;
  metaData:MetaData;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class ShariaComplianceController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  userDataListCall:string|Message=''
  addDataCall:string|Message=''
  shariaComplianceDataApiCallId:string|Message=''
  seoCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      loading: false,
      ShariaCompliancedata:[] ,
      selectLanguage:"",
      metaData: {
        data: {
          attributes: {
            keywords:"",
            page_name: "",
            meta_title:"",
            meta_description:""
          }
        }
      },
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.shariaComplianceDataApiCallId !== null &&
      this.shariaComplianceDataApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson1 = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson1) {
        this.setState({
           ShariaCompliancedata: responseJson1.data,
          loading: false,
        });
      } else {
        this.setState({
          loading: false
        });
        
      }
    };
    this.recevieFunction(message)   
    // Customizable Area End
  }

  // Customizable Area Start

  recevieFunction = (message: Message) => {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) 
      {
      let responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
      )
      if (this.isValidResponse(responseJson)) {
          this.apiSucessCall(apiRequestCallId, responseJson);
      }
     
    }
  }
async componentDidMount() {
  this.getTermsandConditionData();
  this.postSeo()
  const languages = await getStorageData("appLanguage") || "ar";
    this.setState({ selectLanguage: languages })
}

getTermsandConditionData = async () => {
    this.setState({ loading: true });
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const shariaCompliancerequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.shariaComplianceDataApiCallId = shariaCompliancerequestMessage.messageId;

    shariaCompliancerequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.shariaComplianceApi
    );

    shariaCompliancerequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    shariaCompliancerequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(shariaCompliancerequestMessage.id, shariaCompliancerequestMessage);
    return true;
  };
  apiSucessCall = async (apiRequestCallId: string, responseJson: ValidResponseType) => {
    if (apiRequestCallId === this.seoCallId) {
      this.seoSucessCallBack(responseJson);
    }
  };

  seoSucessCallBack = (responseJson: ValidResponseType) => {
    this.setState({
      metaData: responseJson,
      loading: false
    })
  }

  apiCall = async (apiData: APIPayloadType) => {
    const { contentType, method, endPoint, body, type } = apiData;
    let token = await getStorageData("token")
    const header = {
      "Content-Type": contentType,
      token: token
    };
    const requestMessageSc = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageSc.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessageSc.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageSc.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body && type !== "formData"
      ? requestMessageSc.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      : requestMessageSc.addData(
        
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessageSc.id, requestMessageSc);
    return requestMessageSc.messageId;
  };

  isValidResponse = (responseJson: ValidResponseType) => {
    return (responseJson.data)
  };

  postSeo = async () => {
    this.setState({ loading: true })
    this.seoCallId = await this.apiCall({
      method: configJSON.postMethod,
      endPoint: `${configJSON.seoEndPoint}ShariaCompliance`,
      contentType: configJSON.validationApiContentType,
    });
  };
  // Customizable Area End
}
