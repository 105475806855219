// Customizable Area Start
import React from "react";
import {
  Modal,
  Box,
  Button,
  Typography,
  Input, TextField, Grid, Snackbar, Alert
} from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider, styled
} from "@mui/material/styles";
import Loader from "../../../../packages/components/src/Loader.web";
import { translate, getInterFontByLanguage, getPoppinsFontByLanguage, getTextAlignByLanguage, getDirectionsByLanguage, getTextAlignRightByLanguage } from "../../../../packages/components/src/Translate";
import {Helmet} from "react-helmet";
import ContactusController, { Props } from "./ContactusController";

import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { ContactImage, FacebookIcon, InstagramIcon, TwitterIcon, SnapIcon, TiktokIcon, LinkIcon } from './assets';
import Select from "react-select";
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const validationSchema = () => {
  return Yup.object({
    name: Yup.string()
      .required(translate('nameRequiredContactUs'))
      .max(80, translate('nameMaxLengthContactUs'))
      .transform((value) => value.trim()),

    mobileNumber: Yup.string()
      .required(translate('mobileNumberRequiredContactUs'))
      .transform((value) => value.trim())
      .test(
        'valid-mobile-number',
        translate('mobileNumberInvalidContactUs'),
        (value) => /^\d{10}$/.test(value) && value.startsWith('0')
      ),

    type: Yup.string()
      .required(translate('typeRequiredContactUs'))
      .transform((value) => value.trim()),

    message: Yup.string()
      .required(translate('messageRequiredContactUs'))
      .min(50, translate('messageMinLengthContactUs'))
      .max(400, translate('messageMaxLengthContactUs'))
      .transform((value) => value.trim()),
  });
}
const initialValues = {
  name: '',
  mobileNumber: '',
  type: '',
  message: '',
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

// Customizable Area End

export default class Contactus extends ContactusController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderSocailMedia = () => {
    const { contactDetails } = this.state;
    return (
      <SocialMediaBox>
        {contactDetails.facebook_visible && contactDetails.facebook_url && (
          <img
            className="imageClass"
            src={FacebookIcon}
            alt="facebook"
            onClick={() => this.openLinkInNewTab(contactDetails.facebook_url)}
          />
        )}
        {contactDetails.instagram_visible && contactDetails.instagram_url && (
          <img
            data-test-id="openLinkInNewTabTestId"
            className="imageClass"
            src={InstagramIcon}
            alt="insta"
            onClick={() => this.openLinkInNewTab(contactDetails.instagram_url)}
          />
        )}
        {contactDetails.twitter_visible && contactDetails.twitter_url && (
          <img
            className="imageClass"
            src={TwitterIcon}
            alt="twitter"
            onClick={() => this.openLinkInNewTab(contactDetails.twitter_url)}
          />
        )}
        {contactDetails.snapchat_visible && contactDetails.snapchat_url && (
          <img
            className="imageClass"
            src={SnapIcon}
            alt="snap"
            onClick={() => this.openLinkInNewTab(contactDetails.snapchat_url)}
          />
        )}
        {contactDetails.tiktok_visible && contactDetails.tiktok_url && (
          <img
            className="imageClass"
            src={TiktokIcon}
            alt="tiktok"
            onClick={() => this.openLinkInNewTab(contactDetails.tiktok_url)}
          />
        )}
        {contactDetails.linkedin_visible && contactDetails.linkedin_url && (
          <img
            className="imageClass"
            src={LinkIcon}
            alt="linkedin"
            onClick={() => this.openLinkInNewTab(contactDetails.linkedin_url)}
          />
        )}
      </SocialMediaBox>
    );
  };
  renderForm =()=>{
    return(
      <Formik
      validationSchema={validationSchema}
      data-test-id="address-dialog"
      initialValues={initialValues}
      onSubmit={(values, actions) => this.handleSubmit(values, actions)}
    >
      {({
        values,
        touched,
        errors,
        handleBlur,
        handleSubmit,
        handleChange,
        setFieldValue,
        isValid,
      }) => {
        return (
          <StyledForm onSubmit={handleSubmit}>
            <Grid container spacing={2} >
              <Grid item xs={12} md={4} >
                <Field
                  sx={{
                    ...nameStyle,
                    "& .MuiInputBase-input, & .MuiInputBase-input-MuiInput-input": {
                      fontFamily: getInterFontByLanguage(),
                    },
                    "& .MuiInputBase-input::placeholder" :{
                      textAlign:getTextAlignByLanguage()
                    },
                  }}
                  as={TextField}
                  fullWidth
                  variant='standard'
                  name="name"
                  data-test-id="name"
                  placeholder={translate("namePlaceholderContactUs")}
                  onChange={(e:any) => this.handleFieldChange(e, setFieldValue, "name")}
                  onBlur={(e:any) => this.handleFieldBlur(e, setFieldValue, handleBlur, "name")}
                  value={values.name}
                  error={touched.name && Boolean(errors.name)}
                  helperText={<ErrorMessage className="errorMsgSTyle" name="name" />}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Field
                  sx={{
                    ...mobilenumStyle,
                    "& .MuiInputBase-input, & .MuiInputBase-input-MuiInput-input": {
                      fontFamily: getInterFontByLanguage(),
                      textAlign:getTextAlignByLanguage()
                    },
                    "& .MuiInputBase-input::placeholder" :{
                      textAlign:getTextAlignByLanguage(),
                    },
                  }}
                  as={TextField}
                  fullWidth
                  variant="standard"
                  name="mobileNumber"
                  data-test-id="mobileNumber"
                  placeholder={translate("mobileNumberPlaceholderContactUs")}
                  onChange={(e:any) => this.handleFieldChange(e, setFieldValue, "mobileNumber")}
                  onBlur={(e:any) => this.handleFieldBlur(e, setFieldValue, handleBlur, "mobileNumber")}
                  value={values.mobileNumber}
                  error={touched.mobileNumber && Boolean(errors.mobileNumber)}
                  helperText={<ErrorMessage className="errorMsgSTyle" name="mobileNumber" data-test-id="phoneError" />}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <StyledSelect
                  data-test-id="styledSelect"
                  fullWidth
                  sx={{
                    ...optonsStyle,
                    "& .MuiInputBase-input-MuiInput-input": {
                      fontFamily: getInterFontByLanguage(),
                      textAlign:getTextAlignByLanguage(),
                    },
                    "& .css-1wa3eu0-placeholder": {
                       color: 'rgb(159 151 151)'
                    }
                  }}
                  name="type"
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: '#fff',
                      border: 'none',
                      boxShadow: 'none',
                      borderBottom: `1px solid ${state.isFocused ? '#000' : '#ccc'}`,
                      borderRadius: 0,
                      fontFamily: getInterFontByLanguage(),
                      textAlign:getTextAlignByLanguage(),
                      direction: getDirectionsByLanguage(),
                      fontSize: '16px',
                    }),
                  }}
                  onChange={(option: unknown) => setFieldValue('type', option ? (option as { value: string }).value : '')}
                  value={values.type ? { label: values.type, value: values.type } : null}
                  options={[ 
                    { label: translate("realEstateDeveloperContactUs"), value: translate("realEstateDeveloperContactUs") }, 
                    { label: translate("landlordContactUs"), value: translate("landlordContactUs") }, 
                    { label: translate("realEstateAgentContactUs"), value: translate("realEstateAgentContactUs") }, 
                    { label: translate("existingClientContactUs"), value: translate("existingClientContactUs") }, 
                    { label: translate("othersContactUs"), value: translate("othersContactUs") } 
                  ]}
                  isSearchable={false}
                  placeholder={translate("typePlaceholderContactUs")}
                  classNamePrefix="react-select"
                />
                <ErrorMessage data-test-id="typeErrorMsg" name="type">
                  {msg => <div style={{ color: 'rgb(211, 47, 47)', fontFamily: getPoppinsFontByLanguage(), fontSize: '12px', fontWeight: 400, }}>{msg}</div>}
                </ErrorMessage>
              </Grid>
              <Grid item md={12} xs={12}>
                <Field
                  sx={{
                    ...messageStyle,
                    "& .MuiInputBase-input, & .MuiInputBase-input-MuiInput-input": {
                      fontFamily: getInterFontByLanguage(),
                      textAlign:getTextAlignByLanguage()
                    },
                    "& .MuiInputBase-input::placeholder" :{
                      textAlign:getTextAlignByLanguage(),
                    }
                  }}
                  as={Input}
                  fullWidth
                  placeholder={translate("messagePlaceholderContactUs")}
                  multiline
                  minRows={4}
                  name="message"
                  data-test-id="message"
                  onChange={(e:any) => this.handleFieldChange(e, setFieldValue, "message")}
                  onBlur={(e:any) => this.handleFieldBlur(e, setFieldValue, handleBlur, "message")}
                  value={values.message}
                  error={touched.message && Boolean(errors.message)}
                />
                <ErrorMessage data-test-id="msgError" name="message">
                  {msg => <div className="errorMsgSTyle">{msg}</div>}
                </ErrorMessage>
              </Grid>
              <Box style={{ width: '100%' }}>
                <Button data-test-id="submitBtn" 
                 sx={buttonStyle} 
                  style={{
                    fontFamily: getPoppinsFontByLanguage(),
                    textAlign: getTextAlignByLanguage(),
                  }}
                variant='contained' type="submit" fullWidth>
                  {translate("submitButtonContactUs")}
                </Button>
              </Box>
            </Grid>
          </StyledForm>
        );
                    }}
    </Formik>
    )
  } 
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { sendDataMsg, isSnackbarError, isSnackbarSuccess ,contactDetails,appLanguage, isMobile, copied ,metaData} = this.state;
     // Customizable Area End
    return (
      // Customizable Area Start
      <StyledEngineProvider injectFirst>
        
        <Helmet>
          <meta charSet="utf-8" />
          <title>{metaData.data.attributes.meta_title}</title>
          <meta name="description" content="Helmet application" />
          <meta name="keywords" content={metaData.data.attributes.keywords} />
          <meta property="og:title" content={metaData.data.attributes.meta_title} />
          <meta property="og:description" content={metaData.data.attributes.meta_description} />
          </Helmet>
        <div id="main_block" ref={this.firstDivRef}>
          <NavigationMenu navigation={this.props.navigation} id="" isFooter={true} >
            <ThemeProvider theme={theme}>
              <Box sx={mainBoxStyle}>
                <Box sx={contactBoxStyle}>
                  <TextWrapper>
                    <Typography sx={startTextStyle}>
                      {appLanguage === 'en'
                        ? contactDetails.title
                        : contactDetails.title_ar}
                    </Typography>

                    <Box sx={textGroupStyle}>
                      <Typography sx={textStyle}>
                      {appLanguage === 'en'
                        ? contactDetails.body
                        : contactDetails.body_ar}
                       </Typography>
                    </Box>
                  </TextWrapper>
                  <StyledImg src={ContactImage} alt="contactus_img" />
                </Box>
                 <CustomInputsWrapper >
                  {sendDataMsg && (
                    <Snackbar data-test-id="snackBarId"
                       autoHideDuration={4000}
                      onClose={() => this.handleSnackbarself()}
                      className="snackbarStyle"
                      open={isSnackbarError || isSnackbarSuccess}                      >
                      <Alert severity={isSnackbarError ? "error" : "success"}> 
                        {sendDataMsg} </Alert>
                    </Snackbar>
                  )}
                 
                  {this.renderForm()}
                </CustomInputsWrapper>
                <CustomReachUsWrapper>
                  <Typography className="textContainer">
                    {translate('reachUsTitleContactUs')}
                  </Typography>
                  <Box className="iconsContainer" component="div">
                    <Box component="span" className="iconsStack">
                      {this.renderSocailMedia()}
                    </Box>
                    <Typography
                      className="mobileNumTypography"
                      component="a"
                      href={isMobile ? `tel:${contactDetails.phone_number}` : undefined}
                      onClick={
                        !isMobile
                          ? (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => this.handlePhoneNumberClick(e, contactDetails.phone_number)
                          : undefined
                      }
                      data-test-id="submitBtn"
                      style={{ textDecoration: 'none', color: 'inherit', cursor: 'pointer', fontFamily: getInterFontByLanguage() }}
                    >
                      {contactDetails.phone_number}
                    </Typography>
                    {copied && (
                      <span style={{ color: 'green', fontSize: '14px', fontFamily: getInterFontByLanguage() }}>
                        {translate('copiedToClipboard')}
                      </span>
                    )}
                  </Box>
                </CustomReachUsWrapper>
              </Box>
            </ThemeProvider>
          </NavigationMenu>
        </div>

        <Loader loading={this.state.isLoader} />
      </StyledEngineProvider>
      //Merge Engine End DefaultContainer
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const SocialMediaBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap", // Wrap items when space is insufficient
  gap: "20px",
  justifyContent: "center", // Center items horizontally
  alignItems: "center", // Align items vertically
  "& .imageClass": {
    width: "50px",
    height: "50px",
    cursor: "pointer",
    transition: "transform 0.2s ease-in-out",
    "&:hover": {
      transform: "scale(1.1)",
    },

    [theme.breakpoints.down("md")]: {
      width: "40px",
      height: "40px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "30px",
      height: "30px",
      gap: "10px",
    },
  },
}));
const StyledForm = styled('form')({
  "& .MuiFormHelperText-root.Mui-error ":{
    textAlign:getTextAlignRightByLanguage()
  },
  width: '100%',
  marginBottom: '20px',
  paddingInline: '10px',
  "@media (min-width: 576px)": {
    paddingInline: '15px',
  },
  "@media (min-width: 768px)": {
    paddingInline: '24px',
  },
  "@media (min-width: 1200px)": {
    paddingInline: '99px',
  },
  "@media (min-width: 1536px)": {
    paddingInline: '218px',
  },

  "& .errorMsgSTyle": {
    mt: '6px',
    lineHeight: '36px',
    fontSize: '12px',
    fontWeight: 400,
    color: 'rgb(211, 47, 47)'
  },
  "& .MuiFormHelperText-root" : {
    fontFamily: getPoppinsFontByLanguage()
  }
});

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const mainBoxStyle = {
  display: "flex",
  justifyContent: "center",
  flexDirection: 'column',
  alignItems: "center",
  width: '100%',
  marginTop: { xs: "82px", md: "138px" },
  padding: { xs: "", lg: "0px 0px", xl: "0px 0px" },
};

const contactBoxStyle = {
  display: "flex",
  justifyContent: "space-between",
  background: '#1d36bb',
  width: '100%',
  paddingTop: '20px',
  gap: '12px',
  fontFamily: 'Poppins, sans-serif',
  padding: { xs: "16px", sm: "24", lg: "0px 99px", xl: "0px 218px" },
  "@media (min-width: 200px) and (max-width: 600px)": {
    flexDirection: 'column',
    alignItems: 'center'
  },
};


const TextWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  color: '#FFF',
  gap: '12px',
  marginTop: '28px',
});

const startTextStyle = {
  fontSize: '24px',
  lineHeight: '36px',
  fontWeight: 400,
  color: 'rgba(255, 255, 255, 1)',
  fontFamily:getInterFontByLanguage()
 };

const textGroupStyle = {
  fontSize: '24px',
  lineHeight: '36px',
  fontWeight: 700,
  gap: '16px',
  fontFamily:getPoppinsFontByLanguage(),
    color: '#FFFFFF'
};
const textStyle = {
  fontSize: '24px',
  lineHeight: '36px',
  fontWeight: 700,
  color: '#FFFFFF',
  fontFamily:getPoppinsFontByLanguage()
};


const StyledImg = styled('img')({
  width: '260px',
  "@media (min-width: 576px)": {
    width: '260px',
  },
  "@media (min-width: 768px)": {
    width: '427px',
  },
});

const CustomInputsWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  marginTop: '138px',
  position: 'relative',
  "@media (min-width: 200px) and (max-width: 900px)": {
    marginTop: '37px'
  },
  "& .snackbarStyle": {
    position: 'absolute',
    top: '-412px',
    zIndex: -1,
    marginBottom: '50px',
    "@media (min-width: 1280px)": {
      marginLeft: '73px'
    },
    "@media (min-width: 1600px)": {
      marginLeft: '200px'
    },
  },
});

const nameStyle = {
  marginTop: '6px',
  "@media (min-width: 200px) and (max-width: 900px)": {
  },
};

const mobilenumStyle = {
  marginTop: '6px',
  "@media (min-width: 200px) and (max-width: 900px)": {
    marginTop: '24px'
  },
};

const StyledSelect = styled(Select)({
  marginTop: '0px',
  fontFamily:getPoppinsFontByLanguage() ,
  "& .react-select__value-container": {
    padding: "0px",
    fontWeight: 400,
  },
  "& .react-select__dropdown-indicator": {
    padding: '0px'
  },
  "@media (min-width: 200px) and (max-width: 900px)": {
    marginTop: '24px',
    padding: '2px 0px !important'
  },
});

const optonsStyle = {
  fontFamily: getInterFontByLanguage(),
  fontSize: '16px',
  color: '#0F172A'
}


const messageStyle = {
  marginTop: '68px',
  "@media (min-width: 200px) and (max-width: 900px)": {
    marginTop: '24px'
  },
};

const buttonStyle = {
  width:'235px',
  fontFamily:getPoppinsFontByLanguage(),
  fontWeight: 700,
  textTransform: 'unset',
  marginTop: '69px',
  "@media (min-width: 200px) and (max-width: 900px)": {
    marginTop: '54px'
  },
};

const CustomReachUsWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  gap: '32px',
  width: '100%',
  marginTop: '64px',
  marginBottom: '81px',
  fontFamily:getPoppinsFontByLanguage() ,
  direction: getDirectionsByLanguage(),
  "@media (min-width: 576px)": {
    paddingInline: '15px',
  },
  "@media (min-width: 768px)": {
    paddingInline: '24px',
  },
  "@media (min-width: 1200px)": {
    paddingInline: '99px',
  },
  "@media (min-width: 1536px)": {
    paddingInline: '218px',
  },
  "@media (min-width: 600px) and (max-width: 900px)": {
    marginTop: '58px',
    marginBottom: '63px',
    gap: '29px',

  },
  "@media (min-width: 200px) and (max-width: 600px)": {
    marginTop: '88px',
    marginBottom: '110px',
    gap: '27px',

  },
  "& .textContainer": {
    display: 'flex',
    fontWeight: 700,
    fontSize: '56px',
    lineHeight: '72.8px',
    fontFamily: getInterFontByLanguage(),
    "@media (min-width: 200px) and (max-width: 900px)": {
      fontSize: '24px',
      lineHeight: '31.2px',
      justifyContent: 'center'
    },
  },
  "& .iconsContainer": {
    display: 'flex',
    justifyContent: 'flex-start',
    gap: '15px',
    alignItems: 'center',
    flexWrap: 'wrap',
    "@media (min-width: 200px) and (max-width: 900px)": {
      gap: '32px',
      flexDirection: 'column'
    },
  },
  "& .iconsStack": {
    display: 'flex',
    justifyContent: 'center',
    flexDiretion: 'row',
    flexWrap: 'wrap',
    gap: '24px',
  },
  "& .mobileNumTypography": {
    fontSize: '22px',
    lineHeight: '26.63px',
    fontWeight: 600,
    textAlign: 'center',
    textDecoration: 'none',
    color: 'inherit',
    cursor: 'pointer',
    direction:'ltr'
  }
});
// Customizable Area End
