Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";
exports.calculateInvestEndPoint="bx_block_landingpage2/landing_pages";
exports.getFaqDataEndPoint = "bx_block_landingpage2/faqs";
exports.getWhyChooseDataEndPoint = "bx_block_landingpage2/why_choose_aqsats";

exports.btnExampleTitle = "CLICK ME";
exports.seoEndPoint = "/bx_block_seo_setting/seo_settings/find_seo_meta_tags?page_name=Opensooq";
// Customizable Area End