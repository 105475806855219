Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "NavigationMenu";
exports.labelBodyText = "NavigationMenu Body";

exports.btnExampleTitle = "CLICK ME";
exports.loginBtn = "Login";
exports.EnglishBtn = "English";
exports.HomeBtn = "Home";

exports.openDrawerText = "Open Drawer";
exports.userName = "Jordan Ramsay";
exports.userDesignation = "Miami Herica";
exports.logout = "Logout";

exports.jsonApiContentType = "application/json";
exports.getMenuItemsEndPoint = "navmenu/navigation_menu";
exports.getApiMethodType = "GET";
exports.supportMail = "support@aqsatgroup.com"
exports.myProfile = 'My profile';
exports.shariaLink= "https://builderbuckets.blob.core.windows.net/trackerimages/uploads/document/cfbb6bbc-5150-4924-97ee-9150a545582e/OSIT-4043-01-01-10-24.pdf"
exports.regaLink = "https://builderbuckets.blob.core.windows.net/trackerimages/uploads/document/eac31974-99f8-4907-8c44-765c50f542ab/OS-FAL-Brokerage-2024-07-23.pdf"
// Customizable Area End