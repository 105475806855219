import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { FormikHelpers } from 'formik';
import { getStorageData, removeStorageData } from "framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}
// Customizable Area Start
export interface ForgotPasswordApiResponse {
  data: {
    id: string;
    type: string;
    attributes: {
      full_phone_number: string;
      activated: boolean;
      created_at: string;
      pin: number;
      valid_until: string;
    };
  };
  meta: {
    message: string;
    token: string;
  };
}
// Customizable Area End

interface S {
  // Customizable Area Start
  passwordVisible: boolean;
  passwordVisible2: boolean;
  password: string;
  confirmPassword: string;
  passwordResetSuccessful: boolean;
  formikErrors: string;
  localToken: string;
  forgotPasswordApiResponse: ForgotPasswordApiResponse;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ForgotPasswordResetController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  resetPasswordCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    

    this.state = {
      passwordVisible: false,
      passwordVisible2: false,
      password: "",
      confirmPassword: "",
      passwordResetSuccessful: false,
      formikErrors: "",
      localToken: "",
      forgotPasswordApiResponse: {
        data: {
          id: "",
          type: "",
          attributes: {
            full_phone_number: "",
            activated: false,
            created_at: "",
            pin: 0,
            valid_until: ""
          }
        },
        meta: {
          message: "",
          token: ""
        },
      },
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    super.componentDidMount();
    const token = await getStorageData("token");
    this.setState({ localToken: token });
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.resetPasswordCallId) {
        this.resetHandler(responseJson)
      } else if (errorResponse) {
        const errorMessage = errorResponse.message || "Something went wrong.";
        this.setState({ formikErrors: errorMessage });
      } else {
        this.setState({ formikErrors: "No response received from server." });
      }
    }
  }


  resetHandler = async (responseJson:any) => {
    this.setState({ forgotPasswordApiResponse: responseJson });

        if (responseJson?.message === "Password Reset Successfully") {
            this.setState({passwordResetSuccessful: true})
            try {
              await removeStorageData("token");
            } catch (error) {
            }
        } else if (responseJson?.errors?.length) {
          const error = responseJson.errors[0]?.detail || "Password reset failed.";
          this.setState({ formikErrors: error });
        } else {
          this.setState({ formikErrors: "Unexpected response from server." });
        }
  }

  async resetPassword(values: { password: string; confirmPassword: string }) {
     const appLanguage = await getStorageData("appLanguage");
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
      "token": this.state.localToken
    };
    const data = {
      attributes: {
        password: values.password,
        password_confirmation: values.confirmPassword
      }
    };
    const httpBody = {
      data: data,
      "locale":appLanguage
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.resetPasswordCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resetPassAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToHome() {
    const msg: Message = new Message(getName(MessageEnum.NavigationHomeScreenMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  async handleSubmit(
    values: { password: string; confirmPassword: string },
    { setSubmitting }: FormikHelpers<{ password: string; confirmPassword: string }>
  ) {
    try {
       this.resetPassword({
        password: values.password,
        confirmPassword: values.confirmPassword
      });
    } catch (error) {
      this.setState({ formikErrors: "Failed to reset password. Please try again." });
    } finally {
      setSubmitting(false);
    }
  }

  onPressMenuItem = (screenName:string) => {
    const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
    navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
    navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigateMsg);
 
  };
  // Customizable Area End
}
